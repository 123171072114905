import VueCookies from "vue-cookies";
import Vue from "vue";

Vue.use(VueCookies);

export default {
  setColor(state, color) {
    state.backgroundApp = color.hexa;
  },
  setBankList(state, payload) {
    state.BankList = payload;
  },
  setNewBank(state, payload) {
    state.BankList = payload;
  },
  setHeadlines(state, payload) {
    state.Headlines = payload;
  },
  setEce(state, payload) {
    state.Ece = payload;
  },
  setOnlineuser(state, payload) {
    state.Onlineuser = payload;
  },
  setEceReceivers(state, payload) {
    state.EceReceivers = payload;
  },
  seteceemails(state, payload) {
    state.Eceemails = payload;
  },
  setusergroupmembers(state, payload) {
    state.usergroupmembers = payload;
  },
  setDomainList(state, payload) {
    console.log(payload, "payload");
    state.DomainList = payload;
  },
  setNewNewHeadlines(state, payload) {
    state.Headlines = payload;
  },
  setBankAccount(state, payload) {
    state.BankAccount = payload;
  },
  setNewAccountNumber(state, payload) {
    state.BankAccount = payload;
  },
  setFundsList(state, payload) {
    state.Funds = payload;
  },
  setAndikatorsList(state, payload) {
    state.Andikators = payload;
  },
  setReciveMethodList(state, payload) {
    state.ReciveMethod = payload;
  },
  setcustomergroupList(state, payload) {
    state.customergroup = payload;
  },
  setorgpostList(state, payload) {
    state.orgpost = payload;
  },
  setorgunitList(state, payload) {
    state.orgunit = payload;
  },
  setcompanyList(state, payload) {
    state.company = payload;
  },
  setblogList(state, payload) {
    state.blog = payload;
  },
  setBlogcategoryList(state, payload) {
    state.Blogcategory = payload;
  },
  setProjectList(state, payload) {
    state.ProjectList = payload;
  },
  setProjectgroup(state, payload) {
    state.Projectgroup = payload;
  },
  setContractList(state, payload) {
    state.Contract = payload;
  },
  setContractpaymentList(state, payload) {
    state.Contractpayment = payload;
  },
  setContractSupplement(state, payload) {
    state.ContractSupplement = payload;
  },
  setContractAction(state, payload) {
    state.ContractAction = payload;
  },
  setCustomerContact(state, payload) {
    state.CustomerContact = payload;
  },
  setFavoritePersons(state, payload) {
    state.FavoritePersons = payload;
  },
  setContractgroupList(state, payload) {
    state.Contractgroup = payload;
  },
  setDocumentList(state, payload) {
    state.Document = payload;
  },
  setPersonList(state, payload) {
    state.Person = payload;
  },
  setCustomerList(state, payload) {
    state.Customer = payload;
  },
  setInvoiceList(state, payload) {
    state.Invoice = payload;
  },
  setTicketList(state, payload) {
    state.Tickets = payload;
  },
  setPlanList(state, payload) {
    state.Plans = payload;
  },
  setArchiveList(state, payload) {
    state.Archive = payload;
  },
  setFavoriteList(state, payload) {
    state.Favorite = payload;
  },
  setPageactionList(state, payload) {
    state.Pageaction = payload;
  },
  setSystemList(state, payload) {
    state.System = payload;
  },
  setUsergroupmemberList(state, payload) {
    state.Usergroupmember = payload;
  },
  setUsergroupList(state, payload) {
    state.usergroup = payload;
  },
  setTemplateList(state, payload) {
    state.Template = payload;
  },
  setLinks(state, payload) {
    state.Links = payload;
  },
  setToken(state, payload) {
    state.token = payload;
    Vue.$cookies.set("token", payload);
  },
  changeLoading(state, payload) {
    state.loading = payload;
  },
  setMessage(state, payload) {
    state.message = payload;
    state.message.show = true;
    payload.timeOut
      ? (state.message.timeOut = payload.timeOut)
      : (state.message.timeOut = 7000);
  },
  setMenu(state, payload) {
    if (payload == "") {
      state.menu = payload;
    } else {
      state.menu = payload.records;
    }
  },
  setMini(state, payload) {
    state.mini = payload;
  },

  //latelier
  selectTask(state, selectedTask) {
    state.selectedTask = selectedTask;
  },
  updateShowTaskDetail(state, showTaskDetail) {
    state.showTaskDetail = showTaskDetail;
  },
  updateShowTaskDetailFullscreen(state, showTaskDetailFullscreen) {
    state.showTaskDetailFullscreen = showTaskDetailFullscreen;
  },
  setTaskList(state, taskList) {
    state.taskList = taskList;
  },
  removeTaskList(state, id) {
    state.taskList = state.taskList.filter((x) => x.id != id);
  },
  setTaskItems(state, taskItems) {
    state.taskItems = taskItems;
  },
  addTaskItems(state, taskItems) {
    state.taskItems.push(taskItems);
  },
  removeTaskItem(state, id) {
    state.taskItems = state.taskItems.filter((x) => x.id != id);
  },
  replaceTaskItem(state, task) {
    state.taskItems = state.taskItems.map((u) => (u.id !== task.id ? u : task));
  },
  addTaskcheckItem(state, checkItem) {
    state.taskItems = state.taskItems.map((u) => {
      if (u.id !== checkItem.taskItemId) {
        return u;
      } else {
        if (!u.checkList) u.checkList = [];
        u.checkList.push(checkItem);
        return u;
      }
    });
  },
  removeTaskcheckItem(state, checkItem) {
    state.taskItems = state.taskItems.map((u) => {
      if (u.id !== checkItem.taskItemId) {
        return u;
      } else {
        if (!u.checkList) u.checkList = [];
        u.checkList = u.checkList.filter((x) => x.id != checkItem.id);
        return u;
      }
    });
  },
};
