<template>
  <div class="task-estimations-in-detail elevation-1">
    <v-layout row class="durations">
      <v-flex xs6>
        <v-text-field
          v-model="size"
          :label="$t('Size')"
          class="text"
          type="number"
          prepend-icon="mdi-timer"
          :readonly="loading"
        />
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="spent"
          :label="$t('Spent')"
          class="text"
          type="number"
          prepend-icon="mdi-timelapse"
          :readonly="loading"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "TaskEstimationsInDetail",
  i18n: {
    messages: {
      en: {
        Size: "Size",
        Spent: "Spent",
      },
      fr: {
        Size: "Durée",
        Spent: "Réalisé",
      },
    },
  },
  props: {
    task: {
      default: () => {},
    },
  },
  data() {
    return {
      size: null,
      spent: null,
      loading: false,
    };
  },
  watch: {
    task: {
      immediate: true,
      handler(task) {
        if (task) {
          if (this.size !== task.estimationSize) {
            this.size = task.estimationSize;
          }
          if (this.spent !== task.estimationSpent) {
            this.spent = task.estimationSpent;
          }
        } else {
          this.size = null;
          this.spent = null;
        }
      },
    },
    size(size) {
      if (!size) return;
      this.debounceSize();
    },
    spent(spent) {
      if (!spent) return;
      this.debounceSpent();
    },
  },
  computed: {
    ...mapGetters(["getUrl"]),
  },
  methods: {
    debounceSize: debounce(function () {
      if (!this.task.id) return;
      if (this.task.estimationSize && this.task.estimationSize === this.size) {
        return;
      }
      this.loading = true;
      // Meteor.call("tasks.updateSize", this.task._id, this.size, () => {
      //   this.loading = false;
      // });
      axios
        .put(`${this.getUrl}/taskitem/updatesize/`, {
          Id: this.task.id,
          Token: this.$cookies.get("token"),
          time: this.size,
          Language: 0,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.task.estimationSize = this.size;
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),

    debounceSpent: debounce(function () {
      if (!this.task.id) return;
      if (
        this.task.estimationSpent &&
        this.task.estimationSpent === this.spent
      ) {
        return;
      }
      this.loading = true;
      // Meteor.call("tasks.updateSpent", this.task._id, this.spent, () => {
      //   this.loading = false;
      // });
      axios
        .put(`${this.getUrl}/taskitem/updatespent/`, {
          Id: this.task.id,
          Token: this.$cookies.get("token"),
          time: this.spent,
          Language: 0,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.task.estimationSpent = this.spent;
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),
  },
};
</script>

<style scoped>
.text {
  margin: 24px;
}
</style>
