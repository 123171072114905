<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-colcols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem :headers="headers" v-on:getItem="getData" v-on:getList="getPage"
            v-on:getListBySearch="getSearch" :items="getOnlineuserList" :insertItemData="addData" :editItemData="editData"
            :removeItemData="removeData" :hasAttachment="false" :hasPayment="false" :hasSupplement="false"
            :hasAction="false" :hasContacts="false" :add="false" :edit="false" :remove="true">
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-text-field :rules="nameRules" hide-details label="عنوان بانک" outlined dense
                    v-model="addData.title"></v-text-field>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-text-field :rules="nameRules" hide-details label="عنوان بانک" outlined dense
                    v-model="editData.data.title"></v-text-field>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: { url: "bank/create/", Title: null, test: this.$refs },
      editData: { url: "bank/update/", data: { Title: null } },
      removeData: { url: "onlineuser/close/" },
      headers: [
        {
          text: "عنوان شخص",
          align: "center",
          value: "fullName",
        },
        {
          text: "زمان لاگین",
          align: "center",
          value: "loginDate",
        },
        {
          text: "عنوان شرکت",
          align: "center",
          value: "companyName",
        },
        {
          text: "آدرس دامین",
          align: "center",
          value: "companyWebsiteAddress",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getOnlineuserList", "getUrl"]),
  },
  created() {
    this.getBanks();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "onlineuser/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "onlineuser/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getBanks() {
      this.$store.dispatch("sendList", {
        url: "onlineuser/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/bank/get/`, {
          params: {
            Id: item.bankId,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
