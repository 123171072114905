import { render, staticRenderFns } from "./Letter.vue?vue&type=template&id=cbbaf55e&scoped=true&"
import script from "./Letter.vue?vue&type=script&lang=js&"
export * from "./Letter.vue?vue&type=script&lang=js&"
import style0 from "./Letter.vue?vue&type=style&index=0&id=cbbaf55e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbbaf55e",
  null
  
)

export default component.exports