<template>
  <div ref="tasks" class="tasks dragscroll">
    <template v-for="task in tasks">
      <task
        v-if="showCompleted(task, showHiddenTasks)"
        :key="task.id"
        :task="task"
        :data-id="task.id"
      />
    </template>
  </div>
</template>

<script>
// import { Tasks } from "/imports/api/tasks/tasks.js";
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import { devices } from "@/latelier/imports/devices";
import Sortable from "sortablejs";
import Task from "./Task.vue";

export default {
  components: { Task },
  props: {
    projectId: {
      default: 0,
    },
    listId: {
      default: 0,
    },
    showHiddenTasks: {
      default: false,
    },
  },
  data() {
    return {
      filterName: "",
      sortable: null,
    };
  },
  computed: {
    ...mapGetters(["getUrl", "getTaskItems"]),
    ...mapState("project/filters", {
      selectedLabels: (state) => state.selectedLabels,
      selectedAssignedTos: (state) => state.selectedAssignedTos,
      selectedUpdatedBy: (state) => state.selectedUpdatedBy,
    }),
    tasks() {
      return this.getTaskItems.filter((x) => x.taskListId == this.listId);
    },
  },
  mounted() {
    this.$events.listen("filter-tasks", (name) => {
      this.filterName = name;
    });

    let options = {
      delayOnTouchOnly: true,
      delay: 250,
      animation: 150,
      group: "tasks",
      onUpdate: (event) => {
        this.handleMove(event);
      },
      onAdd: (event) => {
        this.handleMove(event);
      },
    };

    if (devices.isMobile()) {
      options = {
        ...options,
        forceFallback: true,
        fallbackTolerance: 4,
        touchStartThreshold: 4,
      };
    }
    this.sortable = Sortable.create(this.$refs.tasks, options);

    this.$events.listen("task-disable-drag", () => {
      this.sortable.option("disabled", true);
    });
    this.$events.listen("task-enable-drag", () => {
      this.sortable.option("disabled", false);
    });
  },
  beforeDestroy() {
    this.$events.off("filter-tasks");
    this.$events.off("task-disable-drag");
    this.$events.off("task-enable-drag");
    this.sortable.destroy();
  },
  meteor: {
    $subscribe: {},
    tasks: {
      params() {
        return {
          name: this.filterName,
          labels: this.selectedLabels,
          assignedTos: this.selectedAssignedTos,
          updatedBy: this.selectedUpdatedBy,
        };
      },
      deep: false,
      update({ name, labels, assignedTos, updatedBy }) {
        const query = {
          listId: this.listId,
        };

        if (name && name.length > 0) {
          query.name = {
            $regex: `.*${name}.*`,
            $options: "i",
          };
        }

        if (labels && labels.length > 0) {
          query.labels = {
            $in: labels.map((label) => label._id),
          };
        }

        if (assignedTos && assignedTos.length > 0) {
          query.assignedTo = {
            $in: assignedTos,
          };
        }

        if (updatedBy && updatedBy.length > 0) {
          query.updatedBy = {
            $in: updatedBy,
          };
        }

        return Tasks.find(query, { sort: { order: 1 } });
      },
    },
  },
  methods: {
    showCompleted(task, show) {
      if (task.completed && !show) {
        return false;
      }
      return true;
    },

    newTaskInline() {
      Meteor.call(
        "tasks.insert",
        this.projectId,
        this.listId,
        "Nouvelle tâche",
        (error, task) => {
          if (error) {
            return;
          }
          this.$events.fire("task-edit-name", task);
        }
      );
    },
    deleteTask(taskId) {
      //Meteor.call("tasks.remove", taskId);
      axios
        .delete(`${this.getUrl}/taskitem/delete/`, {
          data: {
            Id: taskId,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.dispatch("removeTaskItem", { id: this.listId });
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
              timeOut: 2000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    handleMove(event) {
      const taskId = event.item.dataset.id;
      const index = event.newIndex;
      let order = 0;
      let len = this.tasks.length;

      if (index + 1 < len) {
        const nextTask = this.tasks[index];
        order = nextTask.order - 1;
        //Meteor.call("tasks.move", this.projectId, this.listId, taskId, order);
      } else {
        //Meteor.call("tasks.move", this.projectId, this.listId, taskId);
      }

      axios
        .put(`${this.getUrl}/taskitem/move/`, {
          Id: taskId,
          Token: this.$cookies.get("token"),
          Language: 0,
          TaskListId: this.listId,
          order: order,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("refereshTaskItems");
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
            });
          } else if (data.data.status == 0) {
            this.$store.commit("setMessage", {
              message: data.data.message,
              color: "red",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.tasks {
  @media (min-width: 601px) {
    min-height: 400px;
  }
}
.drag-image .task {
  width: 272px;
}
</style>
