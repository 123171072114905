import { render, staticRenderFns } from "./UserGroups.vue?vue&type=template&id=b6bd5ba4&scoped=true&"
import script from "./UserGroups.vue?vue&type=script&lang=js&"
export * from "./UserGroups.vue?vue&type=script&lang=js&"
import style0 from "./UserGroups.vue?vue&type=style&index=0&id=b6bd5ba4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6bd5ba4",
  null
  
)

export default component.exports