<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getInvoiceList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      label="عنوان"
                      outlined
                      dense
                      hide-details
                      v-model="addData.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <!-- <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="addData.Date"
                          label="تاریخ سند"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          hide-details
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="addData.Date"
                        @change="updateAddDataStartDate(addData.Date)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu> -->
                    <v-row>
                      <v-col cols="2"
                        ><v-icon
                          type="button"
                          class="mt-3"
                          @click="show1 = true"
                        >
                          mdi-calendar
                        </v-icon></v-col
                      >
                      <v-col cols="10">
                        <v-text-field
                          v-model="addData.date"
                          id="addData.Date"
                          type="text"
                          outlined
                          dense
                          :label="addData.date ? '' : 'تاریخ سند'"
                        ></v-text-field>
                        <date-picker
                          v-model="addData.Date"
                          :show="show1"
                          :auto-submit="true"
                          :editable="true"
                          custom-input="#addData.date"
                          @close="show1 = false"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      label="شماره"
                      outlined
                      dense
                      hide-details
                      v-model="addData.number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-select
                      label="طرف حساب"
                      hide-details
                      outlined
                      :items="getCustomerList.records"
                      item-text="name"
                      item-value="customerId"
                      dense
                      v-model="addData.customerFk"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-btn small v-on:click="addItem"
                      ><v-icon small>mdi-plus</v-icon>افزودن اتم جدید به
                      فاکتور</v-btn
                    >
                  </v-col>
                  <v-row
                    v-for="(item, index) in addData.invoiceItems"
                    :key="index"
                  >
                    <v-col cols="5">
                      <v-text-field
                        label="توضیحات ایتم"
                        outlined
                        dense
                        v-model="addData.invoiceItems[index].description"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        label="مبلغ"
                        outlined
                        dense
                        v-model="addData.invoiceItems[index].price"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <!-- <v-btn icon v-on:click="itemNumber--"
                        ><v-icon small>mdi-close</v-icon></v-btn
                      > -->
                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
            </template>
            <template v-slot:editForm>
              <v-card-text>
                <v-row>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      label="عنوان"
                      outlined
                      dense
                      hide-details
                      v-model="editData.data.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-row>
                      <v-col cols="2"
                        ><v-icon
                          type="button"
                          class="mt-3"
                          @click="show2 = true"
                        >
                          mdi-calendar
                        </v-icon></v-col
                      >
                      <v-col cols="10">
                        <v-text-field
                          v-model="editData.data.date"
                          id="addData.Date"
                          type="text"
                          outlined
                          dense
                          :label="editData.data.date ? '' : 'تاریخ سند'"
                        ></v-text-field>
                        <date-picker
                          v-model="editData.data.date"
                          :show="show2"
                          :auto-submit="true"
                          :editable="true"
                          custom-input="#editData.data.date"
                          @close="show2 = false"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-text-field
                      label="شماره"
                      outlined
                      dense
                      hide-details
                      v-model="editData.data.number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-select
                      label="طرف حساب"
                      hide-details
                      outlined
                      :items="getCustomerList.records"
                      item-text="name"
                      item-value="customerId"
                      dense
                      v-model="editData.data.customerFk"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-btn small v-on:click="addItemEdit"
                      ><v-icon small>mdi-plus</v-icon>افزودن اتم جدید به
                      فاکتور</v-btn
                    >
                  </v-col>
                  <v-row
                    v-for="(item, index) in editData.data.invoiceItems"
                    :key="index"
                  >
                    <v-col cols="5">
                      <v-text-field
                        label="توضیحات ایتم"
                        outlined
                        dense
                        v-model="editData.data.invoiceItems[index].description"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        label="مبلغ"
                        outlined
                        dense
                        v-model="editData.data.invoiceItems[index].price"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <!-- <v-btn icon v-on:click="itemNumber--"
                        ><v-icon small>mdi-close</v-icon></v-btn
                      > -->
                    </v-col>
                  </v-row>
                </v-row>
              </v-card-text>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import moment from "jalali-moment";
import { mapGetters } from "vuex";
export default {
  name: "Banks",
  components: {
    MenuLeft,
    AccountingSystem,
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      searchTest: "",
      addItems: null,
      show1: false,
      show2: false,
      addData: {
        url: "invoice/create/",
        customerFk: null,
        date: null,
        domainFk: 68,
        invoiceType: 0,
        number: null,
        title: null,
        invoiceItems: [],
      },
      editData: {
        url: "invoice/update/",
        data: {
          customerFk: null,
          date: null,
          domainFk: 68,
          invoiceType: 0,
          number: null,
          title: null,
          invoiceItems: [],
        },
      },
      removeData: { url: "invoice/delete/" },
      menu1: false,
      itemNumber: 0,
      headers: [
        {
          text: "عنوان",
          align: "right",
          value: "title",
        },
        {
          text: "تاریخ",
          align: "center",
          value: "date",
        },
        {
          text: "شماره",
          align: "center",
          value: "number",
        },
        {
          text: "پیوست",
          align: "center",
          value: "invoiceAttachments",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getInvoiceList", "getCustomerList", "getUrl"]),
  },
  created() {
    this.getInvoice();
    this.getCustomer();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "invoice/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "invoice/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getInvoice() {
      this.$store.dispatch("sendList", {
        url: "invoice/list/",
        page: 1,
        Phrase: "",
      });
    },
    getCustomer() {
      this.$store.dispatch("sendList", {
        url: "customer/list/",
        page: 1,
        Phrase: "",
      });
    },
    updateAddDataStartDate(date) {
      // console.log(date, "ffffff");
      // this.formattedDate = moment(this.editData.data.StartDate, 'YYYY/MM/DD').format('jYYYY/jM/jD');
      this.addData.date = moment(date).locale("fa").format("YYYY-M-D");
    },
    addItem() {
      this.addData.invoiceItems.push({
        invoiceItemId: null,
        description: null,
        price: 0,
      });
    },
    addItemEdit() {
      this.editData.data.invoiceItems.push({
        invoiceItemId: null,
        description: null,
        price: 0,
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/invoice/get/`, {
          params: {
            Id: item.invoiceId,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.editData.data.InvoiceItems = response.data.items;
          // console.log(this.editData.InvoiceItems , "ddddddddddd");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
