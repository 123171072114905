<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <v-card class=" ">
            <!-- delete -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="addForm"
            >
              <v-card class="">
                <v-card-title-close @close="addForm = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="formAdd">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="شخص / شرکت"
                          outlined
                          dense
                          hide-details
                          v-model="addData.name"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="شماره تماس"
                          outlined
                          hide-details
                          dense
                          v-model="addData.tell"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="8">
                        <v-text-field
                          label="شماره همراه"
                          outlined
                          hide-details
                          dense
                          v-model="addData.mobile"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox
                          label="شماره واتس اپ"
                          hide-details
                          dense
                          v-model="addData.whatsApp"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="ایمیل"
                          outlined
                          hide-details
                          dense
                          v-model="addData.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          label="توضیحات"
                          rows="4"
                          row-height="30"
                          hide-details
                          v-model="addData.description"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="addData.customerGroups"
                          :items="getcustomergroupList.records"
                          item-text="customerGroupTitle"
                          item-value="customerGroupId"
                          outlined
                          dense
                          hide-details
                          chips
                          small-chips
                          label="گروه های مشتری"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                      <!-- <v-col cols="12">
                        <v-combobox
                          v-model="addData.tags"
                          label="برچسب ها"
                          :search-input.sync="search"
                          multiple
                          outlined
                          dense
                          persistent-hint
                          small-chips
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  No results matching "<strong>{{
                                    search
                                  }}</strong
                                  >". Press <kbd>enter</kbd> to create a new one
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </v-col> -->
                      <v-col cols="12">
                        <v-btn small v-on:click="addAgent"
                          ><v-icon small>mdi-plus</v-icon>افزودن نماینده</v-btn
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-row
                          v-for="(item, index) in addData.agents"
                          :key="index"
                        >
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="نام"
                              outlined
                              hide-details
                              dense
                              v-model="addData.agents[index].name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="موبایل"
                              outlined
                              hide-details
                              dense
                              v-model="addData.agents[index].mobile"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="تلفن"
                              outlined
                              hide-details
                              dense
                              v-model="addData.agents[index].tell"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="سمت"
                              outlined
                              hide-details
                              dense
                              v-model="addData.agents[index].aff"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="ایمیل"
                              outlined
                              hide-details
                              dense
                              v-model="addData.agents[index].email"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" class="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Edit -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="editForm"
            >
              <v-card>
                <v-card-title-close @close="editForm = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="formEdit">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="شخص / شرکت"
                          outlined
                          dense
                          hide-details
                          v-model="editData.data.name"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="شماره تماس"
                          outlined
                          hide-details
                          dense
                          v-model="editData.data.tell"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="8">
                        <v-text-field
                          label="شماره همراه"
                          outlined
                          hide-details
                          dense
                          v-model="editData.data.mobile"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox
                          label="شماره واتس اپ"
                          hide-details
                          dense
                          v-model="editData.data.whatsApp"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="ایمیل"
                          outlined
                          hide-details
                          dense
                          v-model="editData.data.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          label="توضیحات"
                          rows="4"
                          row-height="30"
                          hide-details
                          v-model="editData.data.description"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editData.data.customerGroupMember"
                          :items="getcustomergroupList.records"
                          item-text="customerGroupTitle"
                          item-value="customerGroupId"
                          outlined
                          dense
                          hide-details
                          chips
                          small-chips
                          label="گروه های مشتری"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                      <!-- <v-col cols="12">
                        <v-combobox
                          v-model="editData.data.tags"
                          label="برچسب ها"
                          :search-input.sync="search"
                          multiple
                          outlined
                          dense
                          persistent-hint
                          small-chips
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title> </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </v-col> -->
                      <v-col cols="12">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12">
                        <v-btn small v-on:click="addAgentEdit"
                          ><v-icon small>mdi-plus</v-icon>افزودن نماینده</v-btn
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-row
                          v-for="(item, index) in editData.data.customerAgents"
                          :key="index"
                        >
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="نام"
                              outlined
                              hide-details
                              dense
                              v-model="editData.data.customerAgents[index].name"
                              :rules="requiredRule"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="موبایل"
                              outlined
                              hide-details
                              dense
                              v-model="
                                editData.data.customerAgents[index].mobile
                              "
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="تلفن"
                              outlined
                              hide-details
                              dense
                              v-model="editData.data.customerAgents[index].tell"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="سمت"
                              outlined
                              hide-details
                              dense
                              v-model="editData.data.customerAgents[index].aff"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="ایمیل"
                              outlined
                              hide-details
                              dense
                              v-model="
                                editData.data.customerAgents[index].email
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12"> <v-divider></v-divider> </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" class="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Attachment -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="attachmentForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="attachmentForm = false">
                  پیوست
                </v-card-title-close>
                <v-card-text>
                  <v-data-table
                    :headers="headersAttachment"
                    :items="attachmentList"
                    :loading="attachmentLoading"
                  >
                    <template v-slot:[`item.createdUserFk`]="{ item }">
                      <span dense>{{
                        getManCompanyFk(item.createdUserFk)
                      }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-bind="attrs"
                            v-on="on"
                            :href="
                              getUrl +
                              '/customer/download?id=' +
                              item.customerAttachmentId +
                              '&Token=' +
                              $cookies.get(`token`)
                            "
                          >
                            <v-icon> mdi-download </v-icon>
                          </a>
                        </template>
                        <span>دانلود</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            @click="deleteAttachment(item)"
                            color="error"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>حذف</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-col cols="6" offset="2">
                    <v-file-input
                      v-model="image"
                      color="deep-purple-accent-4"
                      counter
                      label="انتخاب فایل"
                      placeholder="انتخاب فایل"
                      prepend-icon="mdi-paperclip"
                      variant="outlined"
                      :show-size="1000"
                      v-show="true"
                      ref="fileInput"
                      accept="image/*"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      class="mx-1 my-1"
                      compact
                      color="success"
                      v-on:click="uploadAttachment()"
                      :disabled="this.image == null"
                    >
                      <v-icon small>mdi-undo</v-icon>
                      آپلود
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- contact -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="contactForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="contactForm = false">
                  تماس های مشتری
                </v-card-title-close>
                <v-card-text>
                  <v-card class="rounded-lg">
                    <v-card-actions>
                      <v-btn
                        class="mr-2 success"
                        @click="contactAddForm = true"
                      >
                        <v-icon small> mdi-plus </v-icon>
                        افزودن
                      </v-btn>
                    </v-card-actions>
                    <v-card-title>
                      <v-text-field
                        v-model="searchContact"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="جستجو"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-card-text>
                      <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="contactAddForm"
                      >
                        <v-form ref="contactAddForm">
                          <v-card>
                            <v-card-title>ایجاد</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    label="عنوان"
                                    outlined
                                    hide-details
                                    dense
                                    v-model="contactAddData.title"
                                    :rules="requiredRule"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-row>
                                    <v-col cols="1"
                                      ><v-icon
                                        type="button"
                                        class="mt-3"
                                        @click="showContactDatepicker = true"
                                      >
                                        mdi-calendar
                                      </v-icon></v-col
                                    >
                                    <v-col cols="11">
                                      <v-text-field
                                        v-model="contactAddData.date"
                                        id="contactAddData.date"
                                        type="text"
                                        outlined
                                        dense
                                        :label="
                                          contactAddData.date ? '' : 'تاریخ'
                                        "
                                        :rules="requiredRule"
                                      ></v-text-field>
                                      <date-picker
                                        v-model="contactAddData.date"
                                        :show="showContactDatepicker"
                                        :auto-submit="true"
                                        :editable="true"
                                        custom-input="#contactAddData.date"
                                        @close="showContactDatepicker = false"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                              <v-btn
                                v-on:click="addContactItem()"
                                class="success"
                              >
                                افزودن
                              </v-btn>
                              <v-btn v-on:click="contactAddForm = false">
                                لغو
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-form>
                      </v-dialog>
                      <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="contactEditForm"
                      >
                        <v-form ref="contactEditForm">
                          <v-card>
                            <v-card-title>ویرایش</v-card-title>

                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    label="عنوان"
                                    outlined
                                    hide-details
                                    dense
                                    v-model="contactEditData.data.title"
                                    :rules="requiredRule"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-row>
                                    <v-col cols="1"
                                      ><v-icon
                                        type="button"
                                        class="mt-3"
                                        @click="showContactDatepicker2 = true"
                                      >
                                        mdi-calendar
                                      </v-icon></v-col
                                    >
                                    <v-col cols="11">
                                      <v-text-field
                                        v-model="contactEditData.data.date"
                                        id="contactEditData.data.date"
                                        type="text"
                                        outlined
                                        dense
                                        :label="
                                          contactEditData.data.Date
                                            ? ''
                                            : 'تاریخ'
                                        "
                                        :rules="requiredRule"
                                      ></v-text-field>
                                      <date-picker
                                        v-model="contactEditData.data.date"
                                        :show="showContactDatepicker2"
                                        :auto-submit="true"
                                        :editable="true"
                                        custom-input="#contactEditData.data.date"
                                        @close="showContactDatepicker2 = false"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-card-text>

                            <v-card-actions>
                              <v-btn
                                v-on:click="editContactItem()"
                                class="success"
                              >
                                ویرایش
                              </v-btn>
                              <v-btn v-on:click="contactEditForm = false"
                                >لغو</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-form>
                      </v-dialog>

                      <v-data-table
                        :headers="headerContacts"
                        :items="contactList"
                        :items-per-page="5"
                        :loading="contactLoading"
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                @click="openContctEditForm(item)"
                              >
                                mdi-pencil
                              </v-icon>
                            </template>
                            <span>ویرایش</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                @click="deleteContact(item)"
                                color="error"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>حذف</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <Help
                    v-on:ShowHelp="getDataShowHelp"
                    :sendDataHelp="sampleHelp"
                  ></Help>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="addForm = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <!-- Main -->
            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="getCustomerList.records"
                  hide-default-footer
                  :items-per-page="20"
                  :server-items-length="getCustomerList.record_count"
                  :page="1"
                  :fixed-header="true"
                  :loading="getLoading"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          @click="openFormAttachment(item)"
                        >
                          mdi-paperclip
                        </v-icon>
                      </template>
                      <span>پیوست</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          @click="openFormContact(item)"
                        >
                          mdi-phone-hangup
                        </v-icon>
                      </template>
                      <span>تماس ها</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="Math.ceil(getCustomerList?.record_count / 20) > 1"
                  >
                    <v-pagination
                      v-model="page"
                      :length="
                        getCustomerList?.record_count > 0
                          ? Math.ceil(getCustomerList.record_count / 20)
                          : 0
                      "
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import VCardTitleClose from "../../components/VCardTitleClose";
import DataTableChild from "../../components/DataTableChild.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import Help from "../../components/Help.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
import moment from "jalali-moment";
export default {
  mixins: [myMixin],
  name: "Banks",
  components: {
    VCardTitleClose,
    MenuLeft,
    DataTableChild,
    Help,
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      title: "لیست مشتریان",
      search: "",
      contactForm: false,
      attachmentForm: false,
      page: 1,
      dialogDelete: false,
      addForm: false,
      editForm: false,
      selectedCustomer: null,
      sampleHelp: {
        modal: false,
        title: "مشتریان",
        des: "ورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی هدف بهبود ابزارهای کاربردی می باشد. کتایپ به پایان رسد ومورد استفاده قرار گیرد.",
        audio: "",
        video: "",
      },
      showContactDatepicker: false,
      showContactDatepicker2: false,
      select: [],
      Customer: [],
      numberAgent: 0,
      addData: {
        url: "customer/create/",
        name: null,
        tell: null,
        mobile: null,
        whatsApp: false,
        email: null,
        description: null,
        tags: [],
        customerGroups: [],
        agents: [],
        test: this.$refs,
      },
      editData: {
        url: "customer/update/",
        data: {
          name: null,
          tell: null,
          mobile: null,
          whatsApp: false,
          email: null,
          description: null,
          tags: [],
          customerGroups: [],
          customerAgents: [],
        },
      },
      removeData: { url: "customer/delete/" },
      contactAddData: {
        url: "customercontact/create/",
        title: null,
        date: null,
        customerFk: null,
      },
      contactEditData: {
        url: "customercontact/update/",
        data: {
          title: null,
          date: null,
          customerFk: null,
        },
      },
      paymentAddData: {
        url: "contractpayment/create/",
      },
      headers: [
        {
          text: "عنوان مشتری",
          align: "start",
          value: "name",
        },
        {
          text: "گروه های مشتری",
          align: "start",
          value: "allCustomerGroup",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],

      //attachment
      attachmentLoading: false,
      headersAttachment: [
        {
          text: "عنوان فایل",
          align: "start",
          value: "attachmentFileName",
        },
        {
          text: "ایجاد کننده",
          align: "start",
          value: "createdUserFk",
        },
        {
          text: "تاریخ",
          align: "start",
          value: "createdDate",
        },
        {
          text: "ساعت ثبت",
          align: "start",
          value: "createdTime",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      attachmentList: [],
      searchAttachment: "",
      attachmentForm: false,
      image: null,

      //contact
      headerContacts: [
        {
          text: "عنوان",
          align: "start",
          value: "title",
        },
        {
          text: "تاریخ",
          align: "start",
          value: "date",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      searchContact: "",
      contactList: [],
      allContactList: [],
      contactLoading: false,
      contactAddForm: false,
      contactEditForm: false,
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerList",
      "getcustomergroupList",
      "getPersonList",
      "getUrl",
      "getLoading",
    ]),
    attachmentUrl() {
      return this.getUrl + "/Customer/UploadFile/";
    },
  },
  created() {
    this.getCustomer();
    this.getCustomergroup();
    this.getHelpData();
    this.getPerson();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "customer/list/",
        page: 1,
        Phrase: search,
      });
    },
    getHelpData() {},
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "customer/list/",
        page: item,
        phrase: this.search,
      });
    },
    handlePageChange(page) {
      this.getPage(page);
    },
    searchBoxChanged() {
      this.getPage(1);
    },
    getCustomer() {
      this.$store.dispatch("sendList", {
        url: "customer/list/",
        page: 1,
        phrase: this.search,
      });
    },
    getCustomergroup() {
      this.$store.dispatch("sendList", {
        url: "customergroup/list/",
        page: 1,
        phrase: "",
      });
    },
    getDataShowHelp(item) {
      this.sampleHelp.modal = !this.sampleHelp.modal;
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/customer/get/`, {
          params: {
            Id: item.customerId,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.select = [];
          this.customer = [];
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
          this.editData.data.customerAgents = response.data.agents;
          this.attachmentList = response.data.attachments;
          this.contactAddData.customerFk = item.customerId;
          this.select = response.data.tags;
          this.Customer = response.data.groups;
          this.editData.data.customerGroups = [];
          for (const item of this.customer) {
            this.editData.data.customerGroups.push(item.customerGroupId);
          }
          this.editData.data.tags = [];
          for (const item of this.select) {
            // console.log(item.Phrase , 'item');
            this.editData.data.tags.push(item.phrase);
          }
          // console.log(this.editData.data.tags);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getDataRemoveAttachment(item) {
      console.log(item, "item");
      axios
        .post(`${this.getUrl}/customer/deleteuploadfile/`, {
          Id: item.customerAttachmentId,
          Language: 0,
          Token: this.$cookies.get("token"),
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    getDataContact(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/customercontact/get/`, {
          params: {
            Id: item.customerContactId,
            CustomerFk: item.customerFk,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.contactEditData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadAttachment(e) {
      if (e.attachment) {
        axios
          .post(
            `${this.attachmentUrl}`,
            {
              Attachment: e.attachment,
              CustomerFk: e.item.customerId,
              Token: this.$cookies.get("token"),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((data) => {
            if (data.data.status == 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              this.getData(e.item);
            } else {
              this.$store.commit("setMessage", {
                message: data.data.messge,
                color: "red",
                timeOut: 2000,
              });
            }
          })
          .catch((error) => {
            this.$store.commit("setMessage", {
              message: error.message,
              color: "red",
              timeOut: 2000,
            });
            console.log(error);
          });
      }
    },
    addAgent() {
      this.addData.agents.push({
        customerAgentId: 0,
        name: null,
        aff: null,
        tell: null,
        mobile: null,
        whatsApp: true,
        email: null,
      });
    },
    addAgentEdit() {
      this.editData.data.customerAgents.push({
        customerAgentId: 0,
        name: null,
        aff: null,
        tell: null,
        mobile: null,
        whatsApp: true,
        email: null,
      });
    },
    removeAgent() {
      this.numberAgent--;
    },
    getCustomerContact() {
      this.$store.dispatch("sendListCustomerContact", {
        url: "customercontact/list/",
        id: 38,
      });
    },
    updatePaymentAddEndDate(date) {
      this.contactAddData.date = moment(date).locale("fa").format("YYYY/MM/DD");
    },
    updatePaymentEditEndDate(date) {
      this.contactEditData.data.date = moment(date)
        .locale("fa")
        .format("YYYY/MM/DD");
    },

    addItem() {
      this.$store.dispatch("sendNewitem", { data: this.addData });
      this.addForm = false;
    },

    openFormEdit(item) {
      item.customerGroupMember.forEach(function (element) {
        element.customerGroupId = element.customerGroupFk;
      });
      this.editData.data = JSON.parse(JSON.stringify(item));
      this.editForm = true;
    },
    editItem() {
      if (this.$refs.formEdit.validate()) {
        this.editData.data.customerGroups =
          this.editData.data.customerGroupMember;
        this.$store.dispatch("sendEditItem", {
          data: this.editData,
          id: this.editData.customerId,
        });
        this.editForm = false;
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.removeData = item;
      this.removeData.url = "customer/delete/";
    },
    deleteItemConfirm() {
      if (this.removeData.url == "customer/delete/") {
        this.$store.dispatch("sendRemoveItem", {
          data: this.removeData,
        });
        this.closeDelete();
      } else if (this.removeData.url == "customer/deleteuploadfile/") {
        let data = {
          Id: this.removeData.customerAttachmentId,
          Language: 0,
          Token: this.$cookies.get("token"),
        };
        axios
          .delete(`${this.getUrl}/customer/deleteuploadfile/`, {
            data: data,
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              this.closeDelete();
              this.getDataAttachment(this.selectedCustomer.customerId);
            } else if (data.data.status == 0) {
              this.$store.commit("setMessage", {
                message: data.data.message,
                color: "red",
                timeOut: 2000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      } else if (this.removeData.url == "customercontact/delete/") {
        let data = {
          Id: this.removeData.customerContactId,
          Language: 0,
          Token: this.$cookies.get("token"),
        };
        axios
          .delete(`${this.getUrl}/customercontact/delete/`, {
            data: data,
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              this.closeDelete();
              this.getDataContact(this.selectedCustomer.customerId);
            } else if (data.data.status == 0) {
              this.$store.commit("setMessage", {
                message: data.data.message,
                color: "red",
                timeOut: 2000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    // attachment
    getPerson() {
      this.$store.dispatch("sendList", { url: "person/list/", page: 1 });
    },
    getManCompanyFk(id) {
      let result = this.getPersonList.records.find((item) => {
        return item.manCompanyId == id;
      });
      if (result) return `${result.fname} ${result.lname}`;
    },

    getDataAttachment(customerFk) {
      this.attachmentList = [];
      this.attachmentLoading = true;
      axios
        .get(`${this.getUrl}/customer/attachmentlist/`, {
          params: {
            Count: 1000,
            Page: 1,
            Phrase: "",
            CustomerFk: customerFk,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((data) => {
          this.attachmentList = data.data.records;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.attachmentLoading = false;
        });
    },
    openFormAttachment(item) {
      this.image = null;
      this.selectedCustomer = item;
      this.getDataAttachment(this.selectedCustomer.customerId);
      this.attachmentForm = true;
    },
    uploadAttachment() {
      axios
        .post(
          `${this.getUrl}/customer/uploadfile/`,
          {
            Language: 0,
            Token: this.$cookies.get("token"),
            CustomerFk: this.selectedCustomer.customerId,
            Attachment: this.image,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          if (data.data.status == 1) {
            this.getDataAttachment(this.selectedCustomer.customerId);
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.image = null;
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        });
    },
    deleteAttachment(item) {
      this.removeData = item;
      this.removeData.url = "customer/deleteuploadfile/";
      this.dialogDelete = true;
    },

    //contact
    openFormContact(item) {
      this.selectedCustomer = item;
      this.getDataContact(this.selectedCustomer.customerId);
      this.contactForm = true;
    },
    getDataContact(customerFk) {
      this.contactList = [];
      this.contactLoading = true;
      axios
        .get(`${this.getUrl}/customercontact/list/`, {
          params: {
            Count: 1000,
            Page: 1,
            Phrase: "",
            CustomerFk: customerFk,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((data) => {
          this.contactList = data.data.records;
          this.allContactList = data.data.records;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.contactLoading = false;
        });
    },
    addContactItem() {
      if (this.$refs.contactAddForm.validate()) {
        axios
          .post(`${this.getUrl}/customercontact/create/`, {
            Language: 0,
            Token: this.$cookies.get("token"),
            Title: this.contactAddData.title,
            Date: this.contactAddData.date,
            CustomerFk: this.selectedCustomer.customerId,
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.getDataContact(this.selectedCustomer.customerId);
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              this.contactAddForm = false;
            } else {
              this.$store.commit("setMessage", {
                message: data.data.messge,
                color: "red",
              });
            }
          })
          .catch((error) => {
            this.$store.commit("setMessage", {
              message: error.message,
              color: "red",
              timeOut: 2000,
            });
            console.log(error);
          });
      }
    },
    openContctEditForm(item) {
      this.contactEditData.data = JSON.parse(JSON.stringify(item));
      this.contactEditForm = true;
    },
    editContactItem() {
      if (this.$refs.contactEditForm.validate()) {
        axios
          .put(`${this.getUrl}/customercontact/update/`, {
            Language: 0,
            Token: this.$cookies.get("token"),
            Title: this.contactEditData.data.title,
            Date: this.contactEditData.data.date,
            CustomerFk: this.selectedCustomer.customerId,
            Id: this.contactEditData.data.customerContactId,
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.getDataContact(this.selectedCustomer.customerId);
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              this.contactEditForm = false;
            } else {
              this.$store.commit("setMessage", {
                message: data.data.messge,
                color: "red",
              });
            }
          })
          .catch((error) => {
            this.$store.commit("setMessage", {
              message: error.message,
              color: "red",
              timeOut: 2000,
            });
            console.log(error);
          });
      }
    },
    deleteContact(item) {
      this.removeData = item;
      this.removeData.url = "customercontact/delete/";
      this.dialogDelete = true;
    },
  },
  watch: {
    searchContact(v) {
      v == ""
        ? (this.contactList = this.allContactList)
        : (this.contactList = this.allContactList.filter((x) =>
            x.title.includes(v)
          ));
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
  position: relative;
}
</style>
