<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            :items="getBlogcategoryList"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="عنوان گروه محتوا"
                      outlined
                      dense
                      hide-details
                      v-model="addData.title"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
            <template v-slot:editForm>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="عنوان گروه محتوا"
                      outlined
                      dense
                      hide-details
                      v-model="editData.data.title"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "Banks",
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: { url: "blogcategory/create/", title: null },
      editData: { url: "blogcategory/update/", data: { title: null } },
      removeData: { url: "blogcategory/delete/" },
      headers: [
        {
          text: "عنوان گروه محتوا",
          align: "start",
          value: "title",
        },

        // {
        //   text: 'شناسه ملی',
        //   align: 'start',
        //   value: 'NationalId',
        // },
        // {
        //   text: 'شماره تماس',
        //   align: 'start',
        //   value: 'Phone',
        // },
        // {
        //   text: 'شرکت مادر',
        //   align: 'start',
        //   value: 'ParentCompanyName',
        // },
        // {
        //   text: 'کد پستی',
        //   align: 'start',
        //   value: 'PostBox',
        // },

        // {
        //   text: 'فکس',
        //   align: 'start',
        //   value: 'Fax',
        // },
        // {
        //   text: 'آدرس',
        //   align: 'start',
        //   value: 'Address',
        // },

        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getBlogcategoryList", "getUrl"]),
  },
  created() {
    this.getblogcategoryList();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "blogcategory/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "blogcategory/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getblogcategoryList() {
      this.$store.dispatch("sendList", {
        url: "blogcategory/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/blogcategory/get/`, {
          params: {
            Id: item.blogCategoryId,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((response) => {
          // console.log(response, "==============");
          this.editData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
