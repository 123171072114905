import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
Vue.config.productionTip = false;
import axios from "axios";
import i18n from "@/latelier/imports/i18n/";
import confirm from "@/latelier/imports/confirm/confirm";

axios.defaults.withCredentials = true;
axios.interceptors.response.use(undefined, function (error) {
  if (error.response.status == 401) {
    store.dispatch("logout");
    return Promise.reject(error);
  }
});
Vue.use(require("vue-shortkey"));
Vue.use(confirm, { vuetify });

new Vue({
  i18n,
  router,
  store,
  VueCookies,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
