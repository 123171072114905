<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getArchiveList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        hide-details
                        dense
                        outlined
                        v-model="addData.archiveTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <!-- <v-combobox
                      label="مسیر"
                      hide-details
                      dense
                      outlined
                      v-model="addData.direction"
                    ></v-combobox> -->
                      <v-select
                        :rules="nameRules"
                        label="مسیر"
                        hide-details
                        outlined
                        :items="getArchiveList.records"
                        item-text="archiveTitle"
                        item-value="archiveId"
                        dense
                        v-model="addData.parentFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        hide-details
                        dense
                        outlined
                        v-model="editData.data.archiveTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        label="مسیر"
                        hide-details
                        outlined
                        :items="getArchiveList.records"
                        item-text="archiveTitle"
                        item-value="archiveId"
                        dense
                        v-model="editData.data.parentFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Archive",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "archive/create/",
        ArchiveTitle: null,
        test: this.$refs,
        ParentFk: null,
      },
      editData: {
        url: "archive/update/",
        data: { archiveTitle: null, parentFk: null },
      },
      removeData: { url: "archive/delete/" },
      headers: [
        {
          text: "عنوان ",
          align: "right",
          value: "archiveTitle",
        },
        {
          text: "مسیر کامل",
          align: "center",
          value: "fullName",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  mounted() {
    this.getArchive();
  },
  computed: {
    ...mapGetters(["getArchiveList", "getUrl"]),
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "archive/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      // console.log(item);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "archive/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/archive/get/`, {
          params: {
            Id: item.archiveId,
            Language: 0,
            Token: this.$cookies.get("token"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArchive() {
      this.$store.dispatch("sendList", {
        url: "archive/list/",
        page: 1,
        Phrase: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
