<template>
  <v-col cols="12" class="pt-0">
    <LettersList
      :title="title"
      :headers="headers"
      :sendList="listLetters"
      pageType="AllMyMessage"
      :loading="loading"
      @changed="optionChanged"
      @search="searchChanged"
    ></LettersList>
  </v-col>
</template>

<script>
import LettersList from "../LettersManagment/LettersList.vue";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  components: {
    LettersList,
  },
  data() {
    return {
      title: "",
      listLetters: {},
      loading: false,
      search: "",
      currentPageOption: { page: 1, pageSize: 20 },
      headers: [
        {
          text: "نوع نامه",
          value: "messageTypeStringBadge",
          sortable: false,
          align: "center",
        },
        {
          text: "وضعیت نامه",
          value: "isReaded",
          sortable: false,
          align: "center",
        },
        {
          text: "موضوع نامه",
          value: "subject",
          sortable: false,
          align: "start",
        },
        {
          text: "فرستنده نامه",
          value: "messageFrom",
          sortable: false,
          align: "center",
        },
        {
          text: "شماره نامه",
          value: "displayMessageNumber",
          align: "center",
          sortable: false,
        },
        { text: "تاریخ", value: "createdDate", align: "center" },
        { text: "", value: "title7", align: "center", sortable: false },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getListLetters(1, this.getPageListSetting.count, "");
  },
  computed: {
    ...mapGetters(["getUrl", "getPageListSetting"]),
  },
  methods: {
    optionChanged(option) {
      if (
        option.page != this.currentPageOption.page ||
        option.itemsPerPage != this.currentPageOption.pageSize
      ) {
        this.getListLetters(option.page, option.itemsPerPage, this.search);
        this.currentPageOption.page = option.page;
        this.currentPageOption.pageSize = option.itemsPerPage;
      }
    },
    searchChanged(search) {
      this.search = search;
      this.getListLetters(1, this.currentPageOption.pageSize, search);
    },
    getListLetters(page, pageSize, search) {
      this.loading = true;
      axios
        .get(`${this.getUrl}/message/list/`, {
          params: {
            Count: pageSize,
            Page: page,
            Language: 0,
            MessageType: 0,
            Phrase: search,
            TypeNumber: "string",
            PageType: "AllMyMessage",
            Token: this.$cookies.get("token"),
          },
        })
        .then((data) => {
          this.listLetters = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.section__letters {
  background-color: #eeeeee !important;
  height: 100vh;
}
.router-link-active {
  text-decoration: none !important;
}
.border {
  border: 1px solid black !important;
}
</style>
